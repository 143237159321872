.content-banner {
  position: relative;
  &-bg {
    width: 100%;
    background-position: center !important;
    background-size: cover !important;
    height: 965px;
    @media (min-width: 992px) and (max-height: 800px) {
      height: 600px;
    }
    @media (max-width: 767px) {
      height: 500px;
    }
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
  }
  &-writer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    width: 80%;
    text-align: center;
    h1 {
      font-size: 45px;
      font-family: "Font Light";
      color: $white;
      margin-bottom: 55px;
      line-height: 1.2;
      @media (max-width: 767px) {
        font-size: 32px;
        margin-bottom: 40px;
      }
    }
    &-link {
      width: 180px;
      height: 60px;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 23px;
      font-family: "Font Light";
      color: $white !important;
      transition: 500ms;
      border: 2px solid $orange;
      background: $orange;
      transition: 500ms;
      margin: 0 auto;
      @media (max-width: 767px) {
        font-size: 20px;
        height: 45px;
        width: 160px;
        border-radius: 10px;
      }
      &:hover,
      &:focus {
        cursor: pointer;
        background: $white;
        color: $orange !important;
      }
    }
    p {
      font-size: 50px;
      font-family: "Font Medium";
      color: $white;
      margin-bottom: 70px;
    }
  }
}
.content-home {
  margin-top: -100px;
  z-index: 10;
  position: relative;
  margin-bottom: 120px;
  @media (max-width: 991px) {
    margin-bottom: 30px;
    margin-top: -75px;
  }
  &-itens {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 991px) {
      flex-direction: column;
    }
    &-elements {
      text-align: center;
      width: 33.3%;
      @media (max-width: 991px) {
        width: 100%;
        margin-bottom: 30px;
      }
      &-icon {
        width: 200px;
        height: 200px;
        background: $white;
        box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.31);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        transition: 500ms;
        margin: 0 auto 30px auto;
        @media (max-width: 991px) {
          width: 150px;
          height: 150px;
          svg {
            width: 60px;
          }
        }
        svg {
          fill: $orange;
          transition: 500ms;
        }
        &:hover,
        &:focus {
          cursor: pointer;
          background: $orange;
          svg {
            fill: $white;
          }
        }
      }
      &-text {
        h2 {
          font-size: 25px;
          font-family: "Font Bold";
          color: $orange;
          margin-bottom: 30px;
          @media (max-width: 767px) {
            font-size: 20px;
            margin-bottom: 20px;
          }
        }
        p {
          font-size: 20px;
          font-family: "Font Light";
          color: $gray-medium;
          margin-bottom: 30px;
          height: 125px;
          @media (max-width: 991px) {
            height: 100%;
          }
          @media (min-width: 992px) and (max-width: 1200px) {
            font-size: 16px;
            height: 100px;
            margin-bottom: 15px;
          }
          @media (max-width: 767px) {
            font-size: 16px;
            margin-bottom: 20px;
          }
        }
        a {
          font-size: 20px;
          font-family: "Font Light";
          color: $orange;
          margin-bottom: 30px;
          text-decoration: underline;
          text-decoration-color: underline;
          transition: 500ms;
          @media (max-width: 767px) {
            font-size: 16px;
            margin-bottom: 20px;
          }
          &:hover,
          &:focus {
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
            color: $orange;
            text-decoration: underline;
            text-decoration-color: underline;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.content-home-information {
  padding-bottom: 100px;
  @media (max-width: 767px) {
    padding-bottom: 50px;
  }
  &-itens {
    &-header {
      text-align: center;
      margin-bottom: 60px;
      @media (max-width: 767px) {
        margin-bottom: 30px;
      }
      h2 {
        font-size: 50px;
        font-family: "Font Bold";
        color: $orange;
        margin-bottom: 40px;
        @media (max-width: 767px) {
          font-size: 24px;
          margin-bottom: 20px;
        }
      }
      p {
        font-size: 20px;
        font-family: "Font Light";
        color: $gray-medium;
        margin-bottom: 0px;
        @media (max-width: 767px) {
          font-size: 16px;
        }
      }
    }
    &-image {
      width: 100%;
      text-align: center;
      transition: 500ms;
      // &:hover,
      // &:focus {
      //   opacity: 0.8;
      //   transform: scale(1.02);
      // }
    }
  }
}
.content-home-functionalities {
  background: url("../img/home/bg-funcionalidades.png") no-repeat;
  background-size: cover;
  background-position: center;
  background-color: $orange;
  padding: 90px 0px;
  @media (max-width: 767px) {
    padding: 50px 0px;
  }
  &-itens {
    &-header {
      margin-bottom: 70px;
      text-align: center;
      @media (max-width: 767px) {
        margin-bottom: 40px;
      }
      h2 {
        font-size: 40px;
        font-family: "Font Bold";
        color: $white;
        margin-bottom: 0px;
        @media (max-width: 767px) {
          font-size: 24px;
        }
      }
    }
    &-body {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @media (min-width: 768px) and (max-width: 991px) {
        justify-content: center;
      }
      @media (max-width: 767px) {
        flex-direction: column;
        justify-content: center;
        flex-wrap: initial;
      }
      &-elements {
        width: 25%;
        margin-bottom: 40px;
        @media (min-width: 768px) and (max-width: 991px) {
          width: 33.3%;
        }
        @media (max-width: 767px) {
          width: 100%;
          margin-bottom: 30px;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
        &-icon {
          width: 187px;
          height: 187px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px solid $white;
          border-radius: 100%;
          transition: 500ms;
          margin: 0 auto 30px auto;
          @media (max-width: 767px) {
            margin: 0 auto 20px auto;
            width: 120px;
            height: 120px;
          }
          svg {
            fill: $white;
            transition: 500ms;
            @media (max-width: 767px) {
              max-width: 70px;
            }
          }
          &:hover,
          &:focus {
            cursor: pointer;
            background: $white;
            svg {
              fill: $orange;
            }
          }
        }
        &-text {
          text-align: center;
          p {
            font-size: 18px;
            font-family: "Font Medium";
            color: $white;
            margin-bottom: 0px;
            @media (max-width: 767px) {
              font-size: 16px;
              width: 200px;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}
.content-home-details {
  padding: 100px 0px;
  @media (max-width: 767px) {
    padding: 50px 0px;
  }
  &-itens {
    &-elements {
      display: flex;
      align-items: center;
      margin-bottom: 50px;
      justify-content: space-between;
      @media (max-width: 991px) {
        flex-direction: column;
        justify-content: center;
      }
      &:nth-of-type(2) {
        .content-home-details-itens-elements-image {
          text-align: left;
          @media (max-width: 991px) {
            text-align: center;
          }
        }
        @media (max-width: 991px) {
          flex-direction: column-reverse;
        }
      }
      &:last-of-type {
        margin-bottom: 0;
      }
      &-text {
        width: 50%;
        @media (max-width: 991px) {
          width: 100%;
          margin-bottom: 50px;
        }
        &-header {
          display: flex;
          align-items: center;
          margin-bottom: 50px;
          @media (max-width: 991px) {
            margin-bottom: 30px;
            justify-content: center;
          }
          &-icon {
            width: 140px;
            height: 140px;
            background: $white;
            border: 1px solid $gray-medium;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 30px;
            transition: 500ms;
            @media (max-width: 991px) {
              width: 100px;
              height: 100px;
              margin-right: 20px;
            }
            svg {
              fill: $orange;
              transition: 500ms;
              @media (max-width: 991px) {
                max-width: 50px;
              }
            }
            &:hover,
            &:focus {
              background: $orange;
              border: 1px solid $orange;
              svg {
                fill: $white;
              }
            }
          }
          h2 {
            font-size: 50px;
            font-family: "Font Bold";
            color: $gray-medium;
            margin-bottom: 0px;
            @media (max-width: 991px) {
              font-size: 32px;
            }
            @media (max-width: 359px) {
              font-size: 24px;
            }
          }
        }
        &-item {
          p {
            font-size: 25px;
            font-family: "Font Light";
            color: $gray;
            margin-bottom: 30px;
            @media (max-width: 991px) {
              font-size: 16px;
              text-align: center;
            }
          }
          a {
            width: 180px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 15px;
            background: $white;
            border: 1px solid $orange;
            color: $orange !important;
            font-size: 23px;
            font-family: "Font Light";
            transition: 500ms;
            @media (max-width: 991px) {
              font-size: 18px;
              height: 45px;
              width: 150px;
              border-radius: 10px;
              margin: 0 auto;
            }
            &:hover,
            &:focus {
              cursor: pointer;
              background: $orange;
              color: $white !important;
            }
          }
        }
      }
      &-image {
        width: 50%;
        transition: 500ms;
        text-align: right;
        @media (max-width: 991px) {
          width: 100%;
          text-align: center;
        }
        &:hover,
        &:focus {
          opacity: 0.8;
          transform: scale(1.03);
        }
      }
    }
  }
}
.content-company-form {
  padding: 90px 0px;
  background: #f6f6f6;
  @media (max-width: 767px) {
    padding: 50px 0px;
  }
  &-itens {
    h2 {
      font-size: 40px;
      font-family: "Font Bold";
      color: $orange;
      margin-bottom: 60px;
      text-align: center;
      @media (min-width: 768px) and (max-width: 1200px) {
        font-size: 32px;
      }
      @media (max-width: 767px) {
        font-size: 24px;
        margin-bottom: 30px;
      }
    }
  }
  &-element {
    width: 540px;
    margin: 0 auto;
    @media (max-width: 767px) {
      width: 100%;
    }
    &-inputs {
      input,
      select {
        width: 100%;
        height: 45px;
        border: 1px solid #dfdfdf;
        border-radius: 10px;
        padding-left: 20px;
        font-size: 14px;
        font-family: "Font Regular";
        color: $gray-medium;
        margin-bottom: 20px;
        outline: none;
        @media (max-width: 767px) {
          margin-bottom: 15px;
          height: 40px;
          font-size: 12px;
          padding-left: 15px;
        }
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          font-size: 14px;
          font-family: "Font Regular";
          color: $gray-medium;
          @media (max-width: 767px) {
            font-size: 12px;
          }
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          font-size: 14px;
          font-family: "Font Regular";
          color: $gray-medium;
          @media (max-width: 767px) {
            font-size: 12px;
          }
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          font-size: 14px;
          font-family: "Font Regular";
          color: $gray-medium;
          @media (max-width: 767px) {
            font-size: 12px;
          }
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          font-size: 14px;
          font-family: "Font Regular";
          color: $gray-medium;
          @media (max-width: 767px) {
            font-size: 12px;
          }
        }
      }
      textarea {
        width: 100%;
        min-height: 135px;
        border: 1px solid #dfdfdf;
        border-radius: 10px;
        padding-left: 18px;
        padding-top: 15px;
        font-size: 14px;
        font-family: "Font Regular";
        color: $gray-medium;
        margin-bottom: 50px;
        resize: none;
        @media (max-width: 767px) {
          margin-bottom: 30px;
          min-height: 100px;
          font-size: 12px;
          padding-left: 13px;
        }
      }
      &-div {
        display: flex;
        input {
          &:first-of-type {
            width: 60%;
            margin-right: 15px;
            @media (max-width: 767px) {
              margin-right: 10px;
            }
          }
          &:last-of-type {
            width: 40%;
            margin-left: 15px;
            @media (max-width: 767px) {
              margin-left: 10px;
            }
          }
        }
      }
    }
    &-btn {
      width: 180px;
      height: 60px;
      background: $orange;
      border: 1px solid $orange;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 23px;
      font-family: "Font Regular";
      color: $white;
      transition: 500ms;
      margin: 0 auto;
      outline: none;
      box-shadow: none;
      @media (max-width: 767px) {
        height: 45px;
        width: 150px;
        font-size: 18px;
        border-radius: 10px;
      }
      &:hover,
      &:focus {
        cursor: pointer;
        background: $white;
        color: $orange !important;
        outline: none;
        box-shadow: none;
      }
    }
  }
}
.content-home-functionality {
  padding: 80px 0px;
  background: #303030;
  padding-bottom: 0px;
  @media (max-width: 991px) {
    padding: 40px 0px;
    padding-bottom: 0;
  }
  &-itens {
    &-header {
      margin-bottom: 50px;
      text-align: center;
      @media (max-width: 991px) {
        margin-bottom: 30px;
      }
      h2 {
        font-size: 40px;
        font-family: "Font Bold";
        color: $white;
        margin-bottom: 0;
        @media (max-width: 991px) {
          font-size: 24px;
        }
      }
    }
    &-body {
      &-btns {
        background: transparent;
        border: 0;
        margin-bottom: 100px;
        transition: 500ms;
        @media (max-width: 991px){
          margin-bottom: 30px;
        }
        &:hover,&:focus {
          cursor: pointer;
          opacity: 0.8;
          outline: none;
        }
        p {
          font-size: 20px;
          font-family: "Font Medium";
          color: $white;
          margin-bottom: 0;
          width: 170px;
          margin: 50px auto 0px auto;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          @media (max-width: 991px) {
            font-size: 16px;
            margin: 20px auto 0px auto;
          }
        }
      }
    }
  }
}
