.content-footer {
  &-itens {
    &-div {
      &:first-of-type {
        background: #7d7d7d;
      }
      &:nth-of-type(2) {
        background: $black;
      }
      &:nth-of-type(3) {
        background: $orange;
      }
    }
    &-links {
      padding: 60px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 991px) {
        flex-direction: column;
        padding: 30px 0px;
      }
      &-logo {
        padding: 0px 35px 0px 0px;
        @media (max-width: 991px) {
          padding: 30px 0px;
        }
      }
      &-text {
        padding: 10px 60px;
        border-right: 1px solid $white;
        border-left: 1px solid $white;
        @media (min-width: 992px) and (max-width: 1200px) {
          padding: 0px 30px;
        }
        @media (max-width: 991px) {
          padding: 30px 15px;
          border-bottom: 1px solid $white;
          border-top: 1px solid $white;
          border-right: 0;
          border-left: 0;
        }
        p {
          font-size: 16px;
          font-family: "Font Light";
          color: $white;
          margin-bottom: 0px;
          width: 370px;
          @media (max-width: 991px) {
            width: 100%;
            text-align: center;
          }
        }
      }
      &-menu {
        display: flex;
        padding-left: 30px;
        @media (max-width: 991px) {
          padding: 30px 0px;
        }
        &-item {
          &:first-of-type {
            margin-right: 70px;
            @media (min-width: 992px) and (max-width: 1200px) {
              margin-right: 50px;
            }
          }
          a {
            font-size: 16px;
            font-family: "Font Light";
            color: $white;
            margin-bottom: 20px;
            display: block;
            transition: 500ms;
            @media (min-width: 992px) and (max-width: 1200px) {
              margin-bottom: 10px;
            }
            &:last-of-type {
              margin-bottom: 0;
            }
            &:hover,
            &:focus {
              cursor: pointer;
              color: $orange;
              text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
            }
          }
        }
      }
    }
    &-company {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 20px;
      padding-bottom: 20px;
      @media (max-width: 767px) {
        justify-content: center;
        flex-wrap: wrap;
        padding-top: 30px;
        padding-bottom: 10px;
      }
    }
    &-text {
      @media (max-width: 767px) {
        margin-bottom: 30px;
        width: 50%;
        text-align: center;
      }
      p {
        font-size: 8px;
        font-family: "Font Light";
        color: #ababab;
        text-transform: uppercase;
        margin-bottom: 0px;
      }
    }
    &-logos {
      @media (max-width: 767px) {
        margin-bottom: 30px;
        width: 50%;
        text-align: center;
        display: flex;
        justify-content: center;
      }
    }
    &-copyright {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 20px;
      padding-bottom: 20px;
      width: 100%;
      @media (max-width: 767px){
        flex-direction: column;
        justify-content: center;
        padding-top: 30px;
        padding-bottom: 30px;
      }
      &-text {
        font-size: 12px;
        font-family: "Font Light";
        color: $white !important;
        display: block;
        transition: 500ms;
        margin-bottom: 0;
        @media (max-width: 767px){
          margin-bottom: 20px;
        }
        &:hover,
        &:focus {
          text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
          cursor: pointer;
        }
      }
      &-links {
        display: flex;
        align-items: center;
        @media (max-width: 767px){
          flex-direction: column;
          justify-content: center;
        }
        a {
          font-size: 12px;
          font-family: "Font Light";
          color: $white;
          display: block;
          transition: 500ms;
          margin-right: 50px;
          @media (max-width: 767px){
            margin-right: 0;
            margin-bottom: 20px;
            &:last-of-type{
              margin-bottom: 0;
            }
          }
          &:last-of-type {
            margin-right: 0;
          }
          &:hover,
          &:focus {
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
            cursor: pointer;
          }
        }
      }
    }
  }
}
