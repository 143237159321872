.content-banner-writer-rest {
  width: 100%;
  h1 {
    font-size: 50px;
    font-family: "Font Medium";
    color: $white;
    margin-bottom: 70px;
    @media (max-width: 1200px){
      font-size: 40px;
      margin-bottom: 50px;
    }
    @media (max-width: 768px) {
      font-size: 32px;
      margin-bottom: 30px;
    }
  }
  p {
    font-size: 45px;
    font-family: "Font Light";
    color: $white;
    margin-bottom: 10px;
    width: 60%;
    margin: 0 auto;
    @media (max-width: 1200px){
      font-size: 32px;
    }
    @media (max-width: 768px) {
      font-size: 18px;
      width: 90%;
    }
  }
}
.content-restoration {
  padding: 80px 0px 100px 0px;
  background-color: #f3f3f3;
  position: relative;
  margin-bottom: 80px;
  display: flex;
  justify-content: center;
  @media (max-width: 767px) {
    padding: 50px 0px 70px 0px;
    margin-bottom: 50px;
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: 400px;
    height: 1px;
    background-color: $gray-medium;
    @media (max-width: 767px) {
      width: 60%;
    }
  }
  @media (max-width: 767px) {
    padding: 50px 0px;
  }
  &-itens {
    &-header {
      margin-bottom: 50px;
      text-align: center;
      @media (max-width: 767px) {
        margin-bottom: 30px;
      }
      h2 {
        font-size: 32px;
        font-family: "Font Bold";
        color: $orange;
        @media (max-width: 767px) {
          font-size: 24px;
        }
      }
    }
    &-body {
      display: flex;
      align-items: center;
      @media (min-width: 768px) and (max-width: 991px) {
        flex-wrap: wrap;
        justify-content: space-between;
      }
      @media (max-width: 767px) {
        flex-direction: column;
        justify-content: center;
      }
      &-elements {
        width: 25%;
        @media (min-width: 768px) and (max-width: 991px) {
          width: 50%;
          margin-bottom: 50px;
        }
        @media (max-width: 767px) {
          width: 100%;
          margin-bottom: 30px;
        }
        &-icon {
          width: 187px;
          height: 187px;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $white;
          box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.16);
          transition: 500ms;
          margin: 0 auto 40px auto;
          @media (max-width: 767px) {
            margin: 0 auto 20px auto;
            width: 120px;
            height: 120px;
          }
          svg {
            fill: $orange;
            transition: 500ms;
            @media (max-width: 767px) {
              max-width: 50px;
            }
          }
          &:hover,
          &:focus {
            background-color: $orange;
            svg {
              fill: $white;
            }
          }
        }
        p {
          font-size: 20px;
          font-family: "Font Medium";
          color: $orange;
          margin-bottom: 0px;
          text-align: center;
          width: 90%;
          margin: 0 auto;
          @media (max-width: 767px) {
            font-size: 16px;
          }
        }
      }
    }
  }
}
.content-restoration-description {
  overflow: hidden;
  &-itens {
    &-elements {
      display: flex;
      align-items: center;
      position: relative;
      padding: 80px 0px;
			@media (min-width: 768px) and (max-width: 991px){
				padding: 50px 0px;
			}
			@media (max-width: 767px) {
				padding: 50px 0px;
				flex-direction: column;
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        width: 100%;
        height: 1px;
        background-color: #b5b5b5;
      }
      &:nth-of-type(2) {
				@media (max-width: 767px){
					flex-direction: column-reverse;
				}
        .content-restoration-description-itens-image {
          margin-right: initial;
          margin-left: -50vw;
					text-align: right;
					@media (max-width: 767px){
						margin-right: -50vw;
						text-align: center;
					}
        }
      }
    }
    &-text {
			width: 50%;
			@media (max-width: 767px){
				width: 100%;
				margin-bottom: 30px;
			}
      h2 {
        font-size: 40px;
        font-family: "Font Bold";
        color: $orange;
				margin-bottom: 30px;
				@media (min-width: 768px) and (max-width: 991px){
					font-size: 24px;
				}
        @media (max-width: 767px) {
          font-size: 20px;
          margin-bottom: 20px;
        }
      }
      p {
        font-size: 25px;
        font-family: "Font Light";
        color: $gray-medium;
				margin-bottom: 0px;
				@media (min-width: 768px) and (max-width: 991px){
					font-size: 18px;
				}
        @media (max-width: 767px) {
          font-size: 16px;
        }
      }
    }
    &-image {
      width: 100vw;
      position: relative;
      margin-right: -50vw;
			text-align: left;
			transition: 500ms;
			@media (max-width: 767px){
				margin-left: -50vw;
				text-align: center;
			}
			&:hover,&:focus {
				transform: scale(1.03);
			}
    }
  }
}
