.content-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 13;
  transition: 500ms;
  &-scrolled {
    background: $black;
  }
  &-itens {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 60px;
    position: relative;
    @media (min-width: 768px) and (max-width: 1200px) {
      padding: 15px 30px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      flex-direction: column;
    }
    @media (max-width: 767px) {
      padding: 15px;
    }
    &-logo {
      transition: 500ms;
      display: block;
      @media (min-width: 768px) and (max-width: 991px) {
        margin-bottom: 30px;
      }
      @media (max-width: 767px) {
        img {
          max-width: 120px;
        }
      }
      &:hover,
      &:focus {
        cursor: pointer;
        transform: scale(1.01);
      }
    }
    &-links {
      display: flex;
      align-items: center;
      a {
        font-size: 20px;
        font-family: "Font Light";
        color: $white;
        margin-bottom: 0px;
        margin-right: 50px;
        display: block;
        transition: 500ms;
        border-bottom: 3px solid transparent;
        @media (min-width: 768px) and (max-width: 991px) {
          font-size: 16px;
          margin-right: 30px;
        }
        @media (min-width: 992px) and (max-width: 1200px) {
          font-size: 16px;
          margin-right: 30px;
        }
        &:hover,
        &:focus {
          cursor: pointer;
          border-bottom: 3px solid $orange;
        }
      }
      &-submenu {
        svg {
          fill: $white;
          transition: 500ms;
        }
        &:hover,
        &:focus {
          a {
            color: $orange;
            svg {
              fill: $orange;
            }
          }
          .content-header-itens-links-submenu-item {
            opacity: 1;
            pointer-events: initial;
          }
        }
        a {
          font-size: 20px;
          font-family: "Font Light";
          color: $white;
          margin-bottom: 0px;
          display: block;
          margin-right: 0px;
          transition: 500ms;
          &:hover,
          &:focus {
            color: $orange;
            cursor: pointer;
          }
        }
        &-item {
          position: absolute;
          background: #f6f6f6;
          box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.3);
          padding: 15px 20px;
          right: 10px;
          transition: 500ms;
          opacity: 0;
          pointer-events: none;
          a {
            font-size: 16px;
            font-family: "Font Light";
            color: $orange;
            margin-bottom: 10px;
            display: block;
            margin-right: 0;
            transition: 500ms;
            &:last-of-type {
              margin-bottom: 0;
            }
            &:hover,
            &:focus {
              cursor: pointer;
              opacity: 0.6;
              color: $orange;
            }
          }
        }
      }
    }
  }
  .link-active {
    font-family: 'Font Bold';
    border-bottom: 3px solid $orange;
  }
}
.content-menu {
  &-item {
    width: 32px;
    display: block;
    padding-top: 10px;
    &:hover,
    &:focus {
      cursor: pointer;
    }
    span {
      width: 100%;
      height: 3px;
      background-color: $white;
      display: block;
      position: relative;
      transform-origin: center;
      transition: 0.5s ease-in-out;
      margin-bottom: 5px;
      margin-right: 30px;
      &::before {
        content: "";
        transition: 0.5s ease-in-out;
        top: -9px;
        display: block;
        background-color: $white;
        position: absolute;
        height: 100%;
        width: 100%;
      }
      &::after {
        content: "";
        transition: 0.5s ease-in-out;
        bottom: -9px;
        display: block;
        background-color: $white;
        position: absolute;
        height: 100%;
        width: 100%;
      }
    }
    p {
      font-size: 10px;
      color: $white;
      font-family: 'Font Light';
      margin-bottom: 0px;
      margin-top: 12px;
      text-align: center;
    }
    .active {
      background: 0;
      &::before {
        content: "";
        top: 0 !important;
        transform: rotate(45deg) !important;
      }
      &::after {
        content: "";
        bottom: 0 !important;
        transform: rotate(-45deg) !important;
      }
    }
  }
}
