.modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  outline: 0;
}
@media (min-width: 768px) {
  .modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
}
.modal-body {
  position: relative;
  padding: 15px;
}
.modal__body--big {
  padding: 90px 95px 50px;
  @media (max-width: 767px) {
    padding: 10px 20px;
  }
}
button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 35px;
  font-weight: normal;
  line-height: 20px;
  outline: none;
}
.modal--white {
  z-index: 1200;
  .form-control {
    padding: 12px 14px;
    font-size: 0.71429em;
    font-weight: 600;
    color: #bbbbbb;
    background-color: transparent;
    height: 44px;
    border-radius: 0px;
  }
  .form-group {
    margin-bottom: 15px;
  }
  .btn-link.text-danger {
    color: #81001e !important;
    text-transform: uppercase;
    text-decoration: none !important;
    font-size: 10px;
    font-family: "Font Regular";
  }
  .modal__title {
    margin-top: 0;
    margin-bottom: 38px;
    color: $orange;
    text-transform: uppercase;
    font-family: 'Font Bold';
    @media (max-width: 767px) {
      font-size: 20px;
    }
  }
  .modal__bottom-message {
    small {
      color: #939191;
      font-size: 10px;
    }
    .btn-link {
      color: #939191;
      font-size: 12px;
      text-transform: uppercase;
    }
  }
  .form__btn-area {
    margin-top: 30px;
  }
  .btn-login {
    height: 47px;
    width: 216px;
    background-color: #4d4d4f;
    border: 2px solid #4d4d4f;
    color: $white;
    
    font-size: 10px;
    text-transform: uppercase;
    border-radius: 0px;
    margin-top: 30px;
    transition: 500ms;
    &:hover,
    &:focus {
      cursor: pointer;
      border: 2px solid#939191;
    }
  }
  .modal-dialog {
    max-width: 600px;
  }
}
.modal-newsletter {
  @media (min-width: 768px) {
    .modal-dialog {
      max-width: 600px;
      margin: 30px auto;
    }
  }
  @media (min-width: 992px) {
    .modal-lg {
      max-width: 900px;
    }
  }
  .modal-header {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
  }
  .modal__title--big {
    display: inline;
  }
  .modal__title {
    margin-top: 0;
    margin-bottom: 0px;
    color: $orange;
    font-family: 'Font Bold';
    text-transform: uppercase;
  }
  .modal-body {
    p {
      font-size: 16px;

      color: $gray-medium;
    }
    label {
      font-family: "Font Regular";
      font-size: 14px;
      color: #4e4e50;
      margin-bottom: 0px;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }  
    input {
      border: 1px solid #d8d7d3;
      border-radius: 0px;
      color: #4e4e50;
      font-family: "Font Light";
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
}
.modal-body-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  &-confirm {
    width: 250px;
    height: 48px;
    background-color: #8E368C;
    font-size: 15px;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 500ms;
    border: 2px solid #8E368C;
    margin-right: 30px;
    outline: none;
    font-family: 'Font Regular';
    @media (max-width: 767px){
      width: 50%;
      height: 40px;
      font-size: 12px;
      margin-right: 15px;
    }
    &:hover,&:focus {
      cursor: pointer;
      opacity: 0.8;
      color: #8E368C;
      background: $white;
    }
  }
  &-sair {
    width: 200px;
    height: 48px;
    background-color: #DDDDDD;
    font-size: 15px;
    color: #5D5D5D;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 500ms;
    border: 0;
    outline: none;
    @media (max-width: 767px){
      width: 50%;
      height: 40px;
      font-size: 12px;
    }
    &:hover,&:focus {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}
.modal-contact {
  .modal-dialog {
    max-width: 900px;
  }
  .modal-content {
    border-radius: 20px;
    @media (max-width: 767px){
      border-radius: 10px;
    }
  }
  .modal-header {
    margin-bottom: 45px;
    border-bottom: 0;
    padding: 50px 60px 0px 60px;
    @media (max-width: 767px){
      margin-bottom: 0px;
      padding: 30px 30px 0px 30px;
    }
    .modal-title {
      font-size: 40px;
      font-family: 'Font Bold';
      color: $orange;
      margin-bottom: 0px;
      @media (max-width: 767px){
        font-size: 24px;
      }
    }
  }
  .modal-body {
    padding: 0px 60px 60px 60px;
    @media (max-width: 767px){
      padding: 30px 0px 30px 30px;
    }
    &-itens {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 767px){
        flex-direction: column;
        justify-content: flex-start;
      }
      &-elements {
        margin-bottom: 30px;
        @media (max-width: 767px){
          width: 100%;
        }
        &:last-of-type{
          margin-bottom: 0;
        }
        &-header {
          margin-bottom: 20px;
          p {
            font-size: 20px;
            font-family: 'Font Light';
            color: $orange;
            margin-bottom: 0px;
            @media (max-width: 767px){
              font-size: 16px;
            }
          }
        }
        &-text {
          margin-bottom: 30px;
          &:last-of-type{
            margin-bottom: 0;
          }
          h2 {
            font-size: 20px;
            font-family: 'Font Bold';
            color: $gray-medium;
            text-transform: uppercase;
            margin-bottom: 0;
            @media (max-width: 767px){
              font-size: 16px;
            }
          }
          p {   
            font-size: 16px;
            font-family: 'Font Light';
            color: $gray-medium;
            margin-bottom: 0;
            @media (max-width: 767px){
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .modal-body-itens-div {
    @media (max-width: 767px){
      width: 100%;
    }
  }
}
.modal-default {
  .modal-body {
    @media (max-width: 767px){
      padding: 30px;
    }
    p {
      font-size: 20px;
      font-family: 'Font Regular';
      color: $gray-medium;
      margin-bottom: 0px;
      @media (max-width: 991px){
        font-size: 16px;
      }
    }
  }
}