.modalForm {
  &.open {
    @include media-breakpoint-down(lg) {
      transform: translateY(0);
    }
    display: block;
  }
}

.open {
  display: block;
}

.hidden {
  display: none;
}