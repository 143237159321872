.aside-menu {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
  background: $black;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 30px;
  transition: 0.8s ease-in-out;
  left: -100%;
  pointer-events: none;
  z-index: 1030;
  font-family: "Font Regular Bebas";
  &-active {
    left: 0;
    pointer-events: initial;
  }
  &-itens {
    height: 100%;
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 15px;
      border-bottom: 1px solid;
      img {
        max-width: 120px;
      }
    }
    &-links {
      padding-top: 20px;
      a {
        font-family: "Font Light";
        color: $white;
        font-size: 20px;
        display: block;
        width: 100%;
        padding: 15px;
        transition: 500ms;
        &:hover,
        &:focus {
          cursor: pointer;
          color: $orange;
        }
      }
    }
    .card {
      border: 0;
      background: 0;
      border-radius: 0px;
      &-header {
        border: 0;
        background: 0;
        border-radius: 0px;
        padding: 0;
      }
      .btn-link {
        padding: 15px;
        padding-bottom: 0px;
        font-size: 20px;
        color: $white;
        font-family: 'Font Light';
        border: 0;
        width: 100%;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        & > div {
          transition: 500ms;
          margin-left: 10px;
          svg {
            fill: $white;
            transition: 500ms;
          }
        }
      }
      [aria-expanded="true"] {
        color: $orange;
        & > div {
          transform: rotate(180deg);
          svg {
            fill: $orange;
          }
        }
      }
      &-body {
        padding: 0;
        &-links {
          display: flex;
          align-items: center;
          height: 40px;
          width: 100%;
          padding-left: 30px;
          color: $white;
          font-size: 14px !important;
          letter-spacing: 1.5px;
          font-family: 'Font Light';
          transition: 500ms;
          &:hover,&:focus {
            cursor: pointer;
            color: $orange;
          }
        }
      }
    }
  }
  .link-active {
    font-family: 'Font Bold';
    border-bottom: 3px solid $orange;
  }
}
